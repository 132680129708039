import request from '@/utils/request'

/**
 * 获取图片筛选数据
 */
export function fetchPictureLabel(params) {
  return request.post('/fontend/LabelList/picture-label-list', params, {})
}

// 获取图片列表
export function fetchPictureList(params) {
  return request.post('/fontend/picture/list', params, {})
}

// 图片详情
export function fetchPictureInfo(params) {
  return request.post('/fontend/picture/info', params, {})
}