/*
 * @Author: songyajuan
 * @Date: 2021-12-23 17:18:58
 * @Description: 视频相关请求
 * @Last Modified by: songyajuan
 * @Last Modified time: 2021-12-29 11:25:11
 */
import request from '@/utils/request'

/**
 * 获取视频热搜词 
 * 
 */
export function fetchVideoHotSearch(params){
  return request.post('/fontend/hotsearch/list', params, {})
}

/**
 * 视频标签
 */
export function fetchVideoTags() {
  return request.post('/fontend/LabelList/video-label-list')
}

/**
 * 视频列表
 * @param params
 * @param params.page
 * @param params.limit
 * @param params.label_id
 */
export function fetchVideo(params) {
  return request.post('/fontend/Music/videoList', params, {})
}
