<template>
  <div class="filterTag">
    <div class="content">
      <div class="allFilter">
        <div class="container_media">
          <ul :class="allTagMore ? 'tagList' : 'tagList tagList_1'" id="scene-container">
            <span class="tagName tag" style="margin-right: 9px;">场景：</span>
            <li id="tag" class="tag" v-for="(item, idx) in pictureFilterTag" :key="idx" @click="changeTag(idx, item)">
              <img :src="currentTag === idx ? item.label_checked : item.label_icon" alt="">
              <span :class="currentTag === idx ? 'tagName activeTag' : 'tagName'">{{ item.label_name }}</span>
            </li>
          </ul>
          <p class="more-op" @click="allTagMore = !allTagMore">{{ allTagMore ? '收起' : '更多' }}<i
              :class="allTagMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></p>
        </div>
      </div>
      <div class="viceFilter" v-if="label_id">
        <ul class="tagList container_media">
          <span class="tagName">用途：</span>
          <span :class="currentScene === item.label_id ? 'tagName activeTag' : 'tagName'" v-for="(item, idx) in sceneList"
            :key="idx" @click="changeScene(item)">{{
              item.name }}</span>
        </ul>
      </div>
      <div class="bottomFilter container_media flex-between">
        <div class="rowShow">
          <ul class="tagList sortList">
            <span class="tagName">排序：</span>
            <span v-for="(m, i) in sortList" :key="i" :class="m.value === currentSort ? 'tagName activeTag' : 'tagName'"
              @click="setSort(m.value)">{{ m.label }}</span>
          </ul>
          <ul class="tagList">
            <span class="tagName">方向：</span>
            <li class="tag" v-for="(m, i) in directionList" :key="i" @click="changeDirection(m)">
              <img :src="m.value === currentDirection ? m.iconActive : m.icon" alt="">
              <span :class="m.value === currentDirection ? 'tagName activeTag mg0' : 'tagName mg0'">{{ m.label }}</span>
            </li>
          </ul>
        </div>
        <div class="tag-pagina">
          <i class="pagina_l el-icon-arrow-left" :class="{ 'active': lpage }" @click="prevPage"></i>
          <span class="pager">
            <i class="page">{{ pager.page }}</i>
            <i class="pagesize">/{{ pages }}</i>
          </span>
          <i class="pagina_r el-icon-arrow-right" :class="{ 'active': rpage }" @click="nextPage"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPictureLabel } from '@/api/picture'
export default {
  props: {
    pager: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      pictureFilterTag: [],
      allTagMore: false,
      currentTag: 0,
      currentScene: '',
      currentSort: '',
      currentDirection: '',
      lpage: false,
      rpage: false,
      label_id: '',
      sceneList: [],
      sortList: [{
        label: '综合',
        value: ''
      }, {
        label: '最新',
        value: '1'
      }, {
        label: '最热',
        value: '2'
      }],
      directionList: [{
        label: '全部',
        value: '',
        icon: require('@/assets/svg/pictureFilter/pball.svg'),
        iconActive: require('@/assets/svg/pictureFilter/pball-active.svg')
      }, {
        label: '横版',
        value: '1',
        icon: require('@/assets/svg/pictureFilter/hb.svg'),
        iconActive: require('@/assets/svg/pictureFilter/hb-active.svg')
      }, {
        label: '竖版',
        value: '2',
        icon: require('@/assets/svg/pictureFilter/sb.svg'),
        iconActive: require('@/assets/svg/pictureFilter/sb-active.svg')
      }]
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.pager.total / this.pager.limit);
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 判断标签是否折行
    toggleMoreOp() {
      const container = document.querySelector('#scene-container');
      if(!container) return
      const child = container.children;
      const containerWidth = container.offsetWidth;
      let childWidth = 0;
      Array.from(child).forEach((item) => {
        childWidth += item.offsetWidth + parseInt(getComputedStyle(item).marginRight);
      });
      const isWrap = containerWidth < childWidth;
      const moreOp = document.querySelector('.more-op');
      moreOp.style.display = isWrap ? 'block' : 'none';

      window.addEventListener('resize', () => {
        const isWrap = containerWidth < childWidth;
        moreOp.style.display = isWrap ? 'block' : 'none';
      });
    },
    handleEcho() {
      const { currentTag = 0, order = '', page = 1, pattern = '' } = this.$route.query
      this.currentTag = Number(currentTag)
      this.currentSort = order
      this.currentDirection = pattern
      // this.$emit('pagination', {page: page})
      this.$emit('handleEcho', {
        page,
        order,
        pattern,
        argList: this.pictureFilterTag[currentTag]
      })
    },
    changeTag(i, item) {
      let list = []
      this.currentScene = ''
      this.currentTag = i
      // TODO:一期暂时隐藏用途栏
      // this.label_id = item.label_id
      if (item.label_id) {
        list = [{
          name: '全部',
          label_id: ''
        }, ...item.son]
      }
      this.sceneList = list || []
      this.$emit('setLabelId', list, i)
    },
    changeScene(item) {
      this.currentScene = item.label_id
      this.$emit('setLabelId', item.label_id ? [item.label_id] : this.sceneList)
    },
    changeDirection(item) {
      this.currentDirection = item.value
      this.$emit('setDirection', this.currentDirection)
    },
    setSort(v) {
      this.currentSort = v
      this.$emit('setSort', v)
    },
    prevPage() {
      if (this.pager.page < 2) return;
      this.pager.page--;
      this.$emit('getList');
      this.lpage = true;
      this.rpage = false;
    },
    nextPage() {
      if (this.pager.page < this.pages) {
        this.pager.page++;
        this.$emit('getList');
        this.lpage = false;
        this.rpage = true;
      }
    },
    async getList() {
      try {
        const res = await fetchPictureLabel()
        if (res.code === 0) {
          res.data = [{
            label_icon: 'https://qkodo.playlistmusic.com.cn/assets/label/%E5%85%A8%E9%83%A8%E5%88%86%E7%B1%BB.svg',
            label_checked: 'https://qkodo.playlistmusic.com.cn/assets/checked/%E5%85%A8%E9%83%A8%E5%88%86%E7%B1%BB.svg',
            label_id: '',
            son: [],
            label_name: '全部'
          }, ...res.data]
          this.pictureFilterTag = res.data
          this.handleEcho() // 回显之前数据
          this.$nextTick(() => {
            this.toggleMoreOp()
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filterTag {
  @include size(100%, auto);
  background: #FFFFFF;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.1);
  padding: 21px 0 16px;

  .content {
    .tagName {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      white-space: nowrap;
      cursor: pointer;
    }

    .tagList {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .tag {
        display: flex;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;

        img {
          @include square(15px);
          margin-right: 9px;
        }
      }
    }

    .tagList_1 {
      height: 40px;
      overflow: hidden;
    }

    .sortList {
      .tagName {
        &:last-child {
          display: flex;
          align-items: center;
          margin-right: 0 !important;

          &::after {
            content: '';
            @include size(1px, 14px);
            background: #979797;
            display: block;
            margin: 0 30px;
          }
        }
      }
    }

    .activeTag {
      color: #E52A0D;
    }

    .rowShow {
      display: flex;
    }

    .mg0 {
      margin-right: 0 !important;
    }
  }

  .allFilter {
    width: 100%;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 16px;

    .tag {
      margin-bottom: 20px;
    }

    .container_media {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .more-op {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        white-space: nowrap;
        cursor: pointer;

        i {
          @include size(8px, 5px);
          margin-left: 10px;
        }
      }
    }
  }

  .viceFilter {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    .tagName {
      margin-right: 26px;

      &:first-child {
        margin-right: 9px;
      }
    }

    .activeTag {
      display: inline-block;
      padding: 2px 10px;
      background: #FDF4F3;
      border-radius: 4px;
    }
  }

  .flex-between {
    @include center-middle-flex($justify: space-between);
  }

  .tag-pagina {
    @include center-middle-flex($justify: flex-start);
    display: flex;
    align-items: center;

    i {
      font-style: normal;
      display: inline-block;
    }

    .pagina_l,
    .pagina_r {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .pager {
      margin: 0 15px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
    }

    .page {
      color: #E52A0D;
    }

    .active {
      border-color: #E52A0D;
      color: #E52A0D;
    }
  }

  .bottomFilter {
    .tagList {
      .tagName {
        margin-right: 30px;

        &:first-child {
          margin-right: 9px;
        }
      }
    }
  }
}
</style>
