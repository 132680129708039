<template>
  <div class="picture-container">
    <!-- banner -->
    <div class="banner">
      <div class="banner-container">
        <img src="@/assets/image/picture_bg.png" alt="">
      </div>
      <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="search">
        <div class="centerShow">
          <h3>高清图库 一键获取</h3>
          <!-- 搜索框 -->
          <Search :showIdent="false" mode="label_page" class="search-input" style="margin: 0 auto"
            placeholder="请输入图片场景/名称" @search="handleSearch" />
          <!-- 热搜标签 -->
          <div class="hot-tag-box">
            <Tag class="hot-tag-box-item" v-for="(item, index) in hotSearch" :key="index" mode="text"
              @onTag="handleHotTagClick(item)">&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;</Tag>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <filterTag :pager="pageQuery" @getList="getPictureList" @setLabelId="setLabelId" @setSort="setSort"
        @setDirection="setDirection" @handleEcho="handleEcho" />
      <pictureList :fetchLoading="fetchLoading" :list="pictureList" @nextPage="nextPage"
        :endPage="Math.ceil(pageQuery.total / pageQuery.limit) === pageQuery.page" ref="pictureListRef" />
      <!-- 分页器 -->
      <div class="vf-music-pagination" v-if="JSON.stringify(pageQuery) !== '{}'">
        <Pagination v-if="pageQuery.total > 0" :hiddenPage="true" position="center" :limit.sync="pageQuery.limit"
          :page.sync="pageQuery.page" @pagination="pagination" :total="pageQuery.total" />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchVideoTags, fetchVideoHotSearch } from '@/api/video'
import Search from '@/components/Search'
import Tag from '@/components/Tag'
import Buttons from '@/layout/mixins/Buttons'
import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
import filterTag from './components/filterTag.vue'
import pictureList from './components/pictureList.vue'
import Pagination from '@/components/Pagination'
import { fetchPictureList } from '@/api/picture'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'Video',
  components: { Search, Tag, filterTag, pictureList, Pagination, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '图片'}
      ],
      fetchLoading: false,
      tagList: {
        data: [],
        firstActiveIndex: 0,
        secondActiveIndex: -1,
        search: [],
      },
      hotSearch: [],
      pictureList: [],
      pageQuery: {
        total: 0,
        page: 1,
        limit: 50
      },
      argList: [],
      sort: '',
      direction: '',
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    keyword_name() {
      if (this.tagList.search.length > 0 && this.tagList.search.length == 1) {
        return this.tagList.search[0].label_name
      } else if (this.tagList.search.length >= 2) {
        let tagName = [];
        tagName = this.tagList.search.map((item) => {
          return item.label_name
        })
        return tagName
      } else {
        return ''
      }
    }
  },
  created() {
    // this.getTags()
    this.getHot()
  },
  mixins: [Buttons],
  methods: {
    handleEcho(arg) {
      this.pageQuery.page = Number(arg.page)
      this.sort = arg.order
      this.direction = arg.pattern
      if (JSON.stringify(arg.argList) !== '{}') {
        this.argList = arg.argList.son
      }
      this.getPictureList()
    },
    setLabelId(argList, currentTag) {
      this.pageQuery.page = 1;
      this.argList = argList;
      this.getPictureList()
      this.$root.$goSearch({ currentTag: currentTag, page: 1 })
    },
    nextPage() {
      this.pageQuery.page++
      this.getPictureList()
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    async getPictureList() {
      this.fetchLoading = true
      try {
        const params = {
          page: this.pageQuery.page,
          limit: this.pageQuery.limit,
          label_id: this.getLabelIds(this.argList), // 场景
          order: this.sort, // 排序方式
          pattern: this.direction // 方向
        }
        const res = await fetchPictureList(params);
        this.fetchLoading = false
        if (res.code === 0) {
          this.pictureList = res.data.list || []
          this.pageQuery.total = res.data.total
          // 计算最后一个补位元素是否独占一行,独占一行的情况下进行隐藏
          setTimeout(() => {
            const containerMedia = this.$refs.pictureListRef.$refs.container_media_ref
            const after = this.$refs.pictureListRef.$refs.afterRef
            if (containerMedia?.offsetWidth <= after?.offsetWidth) {
              after.style.display = 'none';
            }
          }, 1000)
        }
      } catch (e) {
        console.log(e)
        this.fetchLoading = false
      }
    },
    setSort(v) {
      this.sort = v
      this.getPictureList()
      this.$root.$goSearch({ order: v })
    },
    setDirection(v) {
      this.direction = v
      this.getPictureList()
      this.$root.$goSearch({ pattern: v })
    },
    getLabelIds(list = []) {
      let idStr = ''
      const idList = []
      if (list.length > 1) {
        list.forEach(m => {
          if (m.label_id) {
            idList.push(m.label_id)
          }
        })
        idStr = idList.join(',')
      } else {
        idStr = list[0] || ''
      }
      return idStr
    },
    /**
     * 获取视频热搜词
     */
    async getHot() {
      try {
        const params = {
          type: 5
        }
        const { data } = await fetchVideoHotSearch(params);
        this.hotSearch = data
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 点击热搜标签
     */
    handleHotTagClick(item) {
      const obj = {
        label_name: item.name,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_slt', obj)
      this.$router.push({
        path: '/search-result',
        query: {
          type: 5,
          keyword_name: item.name
        }
      })
    },
    /**
     * 获取视频标签
     */
    async getTags() {
      try {
        const { data } = await fetchVideoTags()
        // console.log(data)
        if (data.length) {
          this.tagList.data = data.map((item) => {
            for (let i = 0; i < item.son.length; i++) {
              item.son[i].checked = false
            }
            return item
          })
          // console.log(this.tagList.data, '视频标签')
          this.updateTagListStatus(this.tagList.data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    pagination(e) {
      this.pageQuery.page = e.page
      this.getPictureList(this.argList)
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.$root.$goSearch({ page: e.page })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.picture-container {
  min-height: 100vh;
  min-width: 1000px;
  padding-bottom: 60px;

  .banner {
    width: 100%;
    position: relative;

    .banner-container {
      width: 100%;
      height: calc(340px + 60px);
      padding-top: 60px;
      box-sizing: border-box;
    }

    img {
      width: 100%;
      height: 100%;
    }
    .crumb-wapper {
      width: 100%;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;

    }
    .search {
      position: absolute;
      @include size(100%, auto);
      left: 0;
      top: 60px;
      transform: translateY(45%);

      h3 {
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 50px;
        text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
        margin: 0 0 24px;
      }

      /deep/.search-container {
        @include size(786px, 56px);

        .input {
          border: none;
          background: rgba(255, 255, 255, 0.8);

          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-moz-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .btn {
          font-size: 18px;
        }
      }
    }

    .hot-tag-box {
      width: 637px;
      overflow: hidden; // 溢出隐藏
      display: -webkit-box; //作为弹性盒伸缩模型
      -webkit-box-orient: vertical; // 从上到下垂直排列
      -webkit-line-clamp: 1; //  显示的行数
      margin: 16px auto 0px;
      line-height: 20px;

      &-item {
        @include font-normal($size: 14px, $color: rgba(255, 255, 255, 0.8));
      }

      &-item:hover {
        color: $theme-color;
      }
    }
  }

  /deep/ {
    .search-container {
      .input-text {
        color: #ffffff;

        .el-input__inner {
          color: #ffffff;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding-left: 24px;
        }

        .el-input__inner::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .content {

    // margin: 0 120px;
    .tags-container {
      width: calc(100% - 240px);
      margin: 26px auto 0;

      /deep/ {
        .el-tabs__item.is-active {
          color: rgba(51, 51, 51, 0.8);
        }

        .el-tabs__item {
          padding: 0 24px !important;
          height: 60px;
          line-height: 54px;
          color: rgba(51, 51, 51, 0.6);
          @include font-bold($size: 18px, $color: rgba(51, 51, 51, 0.48));
        }

        .el-tabs__active-bar {
          height: 4px;
        }

        .el-tabs__nav-wrap::after {
          bottom: 1px;
        }
      }

      .sub-tags-box {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0px;

        &-item {
          @include font-normal($size: 14px, $color: #333);
          line-height: 20px;
          padding: 3px 15px 3px 24px;
        }

        &-item.active {
          background: rgba($theme-color, 0.1);
          border-radius: 16px;
        }
      }

      .sub-tags-third {
        width: 100%;
        height: auto;
        padding: 5px 0px;
        background: #F5F6FA;
        border-radius: 3px;
      }

      .selected-tag-box {
        padding-top: 10px;

        .title {
          @include font-normal($size: 14px, $color: rgba(51, 51, 51, 0.8));
          margin-left: 11px;
        }

        .el-tag {
          border-radius: 20px;
          margin-left: 12px;
          font-size: 14px;
          cursor: pointer;
          padding: 0 15px;
          border-color: transparent;

          /deep/ .el-tag__close {
            font-size: 14px;
            font-weight: bold;
            right: -6px;
          }

          /deep/ .el-tag__close:hover {
            background: none;
            color: $theme-color;
          }
        }

        .clean {
          @include font-normal($size: 12px, $color: #4d4d4f);
          line-height: 17px;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }

    .main {
      @include center-middle-flex;
      flex-direction: column;
      padding: 50px 0 80px;
      position: relative;

      .order_cont {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        right: 125px;
        top: 10px;

        span {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        /deep/.el-select {
          width: 108px !important;
          height: 32px !important;
          border: 1px solid #999999;
          border-radius: 5px;

          .el-input--suffix .el-input__inner {
            width: 108px !important;
            height: 32px !important;
            border: none;
            background: none;
          }

          .el-input__icon {
            line-height: 32px !important;
          }
        }
      }

      &-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 134px 27px;
        grid-auto-flow: row;
        margin-bottom: 134px;
      }
    }
  }
}
</style>
